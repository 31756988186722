import React from "react";
import './styles.scss';
import Wrapper from "../../components/Wrapper";
import { useSelector } from "react-redux";
import OverlayHero from "../../components/OverlayHero";
import DashboardUserInfo from "../../components/DashboardUserInfo";
import assets from "../../shared/assets";
import DashboardSubscription from "../../components/DashboardSubscription";

const Dashboard = () => {
  const { user } = useSelector(reduxState => reduxState.user);
  return (
    <Wrapper>
      <div className="binder">
        <div className="dashboard">
        <div className="dashboard-col left">
          <DashboardUserInfo />
        </div>
        <div className="dashboard-col main">
          <DashboardSubscription />
        </div>
      </div>
      </div>
      <OverlayHero images={assets.homeHero} />
      
    </Wrapper>
  )
}
export default Dashboard;