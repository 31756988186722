import React from 'react'
import './styles.scss';

const Input = ({placeholder, onChangeText, type, value}) => {

    const onChange = (e) => {
        onChangeText(e.target.value);
    }

    return (
    <div className="input-container">
        <div className="subscription-input">
            <input type={type} placeholder={placeholder} onChange={onChange} value={value}/>
        </div>
    </div>
    )
}

Input.defaultProps = {
    type: 'text'
}

export default Input;