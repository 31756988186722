import React from 'react'
import moment from 'moment';
import { useSelector } from 'react-redux';
import './styles.scss';
import { remove } from '../../shared/services/http';

const DashboardSubscription = () => {
    const { user } = useSelector(reduxState => reduxState.user);
    console.log('user', user);
    if(!user || !user.plan){
        return null;
    }

    const q = user.plan.amount / 100;
    const qty = new Intl.NumberFormat('es-MX').format(q);

    const cancel = async () =>  {
        await remove(`user/${user.id}/subscription`);
        window.location.reload();
    }

    return (
        <div className="dashboard-subscription">
            <div className="dashboard-subscription-title">Tu suscripción</div>
            <div className="dashboard-subscription-plan">{user.plan.name}</div>
            <div className="dashboard-subscription-qty">${qty}{user.plan.currency}</div>
            <div className="dashboard-subscription-when">{user.subscription.status}</div>
            <div className="dashboard-subscription-when">Creada el <strong>{moment.unix(user.subscription.created_at).format('LL')}</strong></div>
            <div className="dashboard-subscription-ctas">
                <div className="dashboard-subscription-ctas-cta" onClick={cancel}>
                    Cancelar
                </div>
            </div>
        
        </div>
    )

}

export default DashboardSubscription;