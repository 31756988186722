import React from 'react'
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/error.json';

import './styles.scss';

const ErrorComponent = () => (
    <div className="error-page">
        <Lottie
            width={350}
            options={{
                loop: true,
                autoplay: true, 
                animationData: animationData,
            }}
        />
    </div>
)

export default ErrorComponent;