import React from 'react'

import './styles.scss';
import Menu from '../Menu';

const Wrapper = ({children}) => {
    return (
    <div className="wrapper">
        <Menu />
        <div className="binder">
            {children}
        </div>
    </div>
    )
}

export default Wrapper;