import React from 'react'
import HeroSlider, { Slide } from 'hero-slider';

import './styles.scss';

const OverlayHero = ({images}) => (
    <div className="overlay-hero">
        <HeroSlider
            slidingAnimation='fade'
            settings={{
                slidingDuration: 500,
                slidingDelay: 100,
                shouldAutoplay: true,
                shouldDisplayButtons: false,
                autoplayDuration: 10000,
            }}
        >
            {
                images.map((src, i) => (
                    <Slide background={{backgroundImage: src, backgroundAnimation: 'zoom', backgroundPosition: 'center center'}} />
                ))
            }
        </HeroSlider>
        <div className="overlay-hero-overlay"></div>
    </div>
)

export default OverlayHero;