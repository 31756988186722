import React, { useState } from "react";
import Wrapper from "../../components/Wrapper";
import * as Cookies from 'js-cookie';
import * as queryString from 'query-string';

import './styles.scss';
import OverlayHero from "../../components/OverlayHero";
import assets from "../../shared/assets";
import Input from "../../components/Input";
import { Link, useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../shared/redux/loader/actions";
import { post } from "../../shared/services/http";
import { showAlert } from "../../shared/redux/alert/actions";
import { setUserLogged } from "../../shared/redux/user/actions";

const Login = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const submit = async (e) => {
    e && e.preventDefault();
    dispatch(startLoading());

    const r = await post('/login', {email, password});
    console.log('r', r);

    if(r.data.error){
      dispatch(showAlert('Error', r.data.response));
    }else{
      Cookies.set('PDAP_session_hash', r.data.response.hash);
      dispatch(setUserLogged(r.data.response.hash));
      // eslint-disable-next-line no-restricted-globals
      const parsed = queryString.parse(location.search);
      window.location.href = parsed.redirect_url ? `${parsed.redirect_url}?cookie=${r.data.response.hash}` : window.location.href ;
    }
    dispatch(stopLoading());
  }

  return(
    <Wrapper>
      <div className="binder">
        <OverlayHero images={assets.homeHero} />
        <div className="login-box">
          <div className="login-box-title">Inicia sesión</div>
          <div className="login-box-form">
            <form action="#" onSubmit={submit}>
              <Input 
                placeholder="Correo electrónico"
                value={email}
                onChangeText={(text) => setEmail(text)}
                type="email"
              />
              <Input 
                placeholder="Contraseña"
                value={password}
                onChangeText={(text) => setPassword(text)}
                type="password"
              />
              <div className="login-box-actions">
                <div className="login-box-actions-action">
                  <Link to="/recuperar-contrasena">
                    Olvidé mi contraseña
                  </Link>
                </div>
              </div>
              <Button title="Iniciar sesión" onPress={submit} disabled={!email || !password}/>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default Login;