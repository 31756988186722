import React from "react";
import Wrapper from "../../components/Wrapper";

import './styles.scss';
import OverlayHero from "../../components/OverlayHero";
import assets from "../../shared/assets";
import SubscriptionBox from "../../components/SubscriptionBox";


const Index = (props) => {
  return(
    <Wrapper>
      <div className="index-binder">
        
        <div className="index-binder-content">
          <div className="index-binder-content-left">
            <h2>Únete a la <strong>comunidad</strong>.</h2>
            <p>Construyamos juntos un periodismo independiente. Construyamos las condiciones y los mecanismos para que la información circule libremente, sin que la presión del poder (político, económico, fáctico) la distorsione.</p>
            <p>Sé parte de los nuevos modelos de sustentabilidad que la Alianza de Medios de la Red de Periodistas de a Pie impulsa para cambiar la relación entre prensa y poder.</p>
            <p>Sé parte de la comunidad, sé parte del cambio, suscríbete.</p>
            <div className="beneficios">
              <h3>Beneficios</h3>
              <ul>
                <li>Contenido exclusivo (por 12 horas)</li>
                <li>Navegación sin publicidad</li>
                <li>Actividades y capacitaciones exclusivas de los medios</li>
                <li>Ayudar a construir un periodismo libre</li>
              </ul>
            </div>
          </div>
          <div className="index-binder-content-right">
            <SubscriptionBox />
            
          </div>
        </div>
        <OverlayHero images={assets.homeHero} />
        
      </div>
    </Wrapper>
  )
}
export default Index;