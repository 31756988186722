import { SET_USER_LOGGED, SET_USER_ID, USER_LOGOUT } from './constants';

export const setUserLogged = (hash) => ({
    type: SET_USER_LOGGED,
    hash
})

export const setUser = (user) => ({
    type: SET_USER_ID,
    user
})

export const logoutUser = () => ({
    type: USER_LOGOUT
})