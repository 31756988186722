import React from 'react'
import CreditCardInput from 'react-credit-card-input';
import './styles.scss';
import { setCardField } from '../../shared/redux/subscription/actions';
import { useDispatch } from 'react-redux';

const SubscriptionFormCard = () => {
  const dispatch = useDispatch();
  const onInputChange = (field, value) => {
    switch(field){
      case 'number':
        let number = value.replace(/\s/g, '');
        dispatch(setCardField('number', number));
      break;
      case 'cvc':
        const cvc = value;
        dispatch(setCardField('cvc', cvc));
      break;
      case 'date':
        let dateMMYY = value.replace(/\s/g, '');
            dateMMYY = dateMMYY.replace(/\//g, '');
        const mm = dateMMYY.substr(0, 2);
        const yy = '20' + dateMMYY.substr(2);
        dispatch(setCardField('exp_year', yy));
        dispatch(setCardField('exp_month', mm));
      break;
      default:
        return;
    }
  }

  return (
    <div className="input-container">
        <CreditCardInput 
            containerStyle={{width: '100%'}}
            cardNumberInputProps={{
              onChange: e => onInputChange('number', e.target.value)
            }}
            cardCVCInputProps={{
              onChange: e => onInputChange('cvc', e.target.value),
            }}
            cardExpiryInputProps={{
              onChange: e => onInputChange('date', e.target.value)
            }}
            customTextLabels={{
                invalidCardNumber: 'El número de la tarjeta es inválido',
                expiryError: {
                  invalidExpiryDate: 'La fecha de expiración es inválida',
                  monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
                  yearOutOfRange: 'El año de expiración no puede estar en el pasado',
                  dateOutOfRange: 'La fecha de expiración no puede estar en el pasado'
                },
                invalidCvc: 'El código de seguridad es inválido',
                invalidZipCode: 'El código postal es inválido',
                cardNumberPlaceholder: 'Número de tarjeta',
                expiryPlaceholder: 'MM/AA',
                cvcPlaceholder: 'CVC',
                zipPlaceholder: 'C.P.'
              }}
        />
    </div>
)}
export default SubscriptionFormCard;