import React, { useState } from "react";
import Wrapper from "../../components/Wrapper";

import './styles.scss';
import OverlayHero from "../../components/OverlayHero";
import assets from "../../shared/assets";
import Input from "../../components/Input";
import { Link, useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../shared/redux/loader/actions";
import { post } from "../../shared/services/http";
import { showAlert } from "../../shared/redux/alert/actions";
import store from "../../shared/redux/store";

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();

  const submit = async (e) => {
    const state = store.getState();
    const user = state.user.user;
    dispatch(startLoading());
    const r = await post('/resetpassword', {id: user.id, password});
    console.log('r', r);
    if(r.data.error){
      dispatch(showAlert('Error', r.data.response));
    }else{
      window.location.href = window.location.href;
    }
    dispatch(stopLoading());
  }

  return(
    <Wrapper>
      <div className="binder">
        <div className="login-box">
          <div className="login-box-title">Cambia tu contraseña</div>
          <div className="login-box-des">Por seguridad es necesario que cambies tu contraseña.</div>
          <div className="login-box-form">
            <form action="#" onSubmit={submit}>
              <Input 
                placeholder="Nueva contraseña"
                onChangeText={(text) => setPassword(text)}
                type="password"
              />
              <Input 
                placeholder="Repite la contraseña"
                onChangeText={(text) => setNewPassword(text)}
                type="password"
              />
              <Button title="Cambiar contraseña" onPress={submit} disabled={!password || newPassword !== password}/>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default ResetPassword;