import React from 'react'
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/loader.json';

import './styles.scss';

const Loader = () => {
    const isLoading = useSelector((reduxStore) => reduxStore.loader.isLoading);
    return isLoading ? (
        <div className="loader">
            <Lottie
                width={100}
                options={{
                    loop: true,
                    autoplay: true, 
                    animationData: animationData,
                }}
            />
        </div>
    ) : null
}

export default Loader;