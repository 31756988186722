import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es';

import { useDispatch, useSelector } from 'react-redux';
import store from './shared/redux/store';
import { getInfo } from './shared/redux/info/actions';
import Loader from './components/Loader';
import Alert from './components/Alert';


/** Pages */
import Index from './pages/Index';
import Error from './pages/Error';
import Error404 from './pages/Error404';
import { get, post } from './shared/services/http';
import { stopLoading, startLoading } from './shared/redux/loader/actions';
import Login from './pages/Login';
import { setUser } from './shared/redux/user/actions';
import Dashboard from './pages/Dashboard';
import RecoverPassword from './pages/RecoverPassword';
import ResetPassword from './pages/ResetPassword';


moment.locale('es');


const App = () => {

  const isLoggedFromHash = useSelector(reduxState => reduxState.user.hash ? true : false);

  const state = store.getState();

  const [activeIsSet, setActiveIsSet] = useState(!state.info.loading);
  const [isActive, setIsActive] = useState(0);
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUserState] = useState(null);

  const dispatch = useDispatch();

  dispatch(startLoading());
  dispatch(getInfo());

  useEffect(() => {
    const readActive = async () => {
      const r = await get('info');
      setActiveIsSet(true);
      setIsActive(r.data.isActive);
      dispatch(stopLoading());
    }

  
    const setLogin = async () => {
      const hash = state.user.hash;
      const r = await post('hashretriever', {hash});
      if(!r.data.error){
        setIsLogged(true);
        setUserState(r.data.response);
        dispatch(setUser(r.data.response));
      }
      dispatch(stopLoading());
    }

    if(isLoggedFromHash){
      setLogin();
    }

    readActive();

    
  }, [dispatch]); 


  return(
    <>
      <Router>
          {
            (activeIsSet) && (
                isActive ?
                (
                  !isLogged || !user ?
                  (
                    <Switch>
                      <Route path="/" exact>
                        <Index />
                      </Route>
                      <Route path="/login" exact>
                        <Login />
                      </Route>
                      <Route path="/recuperar-contrasena" exact>
                        <RecoverPassword />
                      </Route>
                      <Route path="*">
                        <Error404 />
                      </Route>
                    </Switch>
                  ) :
                  (
                    <Switch>
                      <Route path="*">
                        {
                          user.resetPassword ?
                          <ResetPassword />
                          : 
                          <Dashboard />
                        }
                      </Route>
                    </Switch>
                  )
                )
                : 
                (
                  <Switch>
                    <Route path="*">
                      <Error />
                    </Route>
                  </Switch>
                ) 
            )
          }
        </Router>
        <Loader />
        <Alert />
      </>
  )
}

export default App;