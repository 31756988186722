import { GET_INFO, GET_INFO_SUCCESS } from './constants';
import { get } from '../../services/http';

export const getInfo = () => (
    async (dispatch) => {
        dispatch(getInfoRequest());
        const r = await get('info');
        dispatch(getInfoSuccess(r.data));
    }
)

export const getInfoRequest = () => ({
    type: GET_INFO
})

export const getInfoSuccess = (data) => ({
    type: GET_INFO_SUCCESS,
    payload: data
})