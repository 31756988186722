import React from 'react'
import './styles.scss';
import { currencyFormatter } from '../../shared/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { startLoading } from '../../shared/redux/loader/actions';
import { tokenizeCard } from '../../shared/helpers';
import { processToken } from '../../shared/redux/subscription/actions';

const SubscriptionSubmit = () => {
    const subscription = useSelector((rs) => rs.subscription);
    const isValid = subscription.planID && subscription.user.isValid && subscription.cardIsValid && subscription.recaptcha ? true : false;
    const dispatch = useDispatch();

    const cs = ['subscription-submit'];
    if(!isValid){
        cs.push('invalid');
    }
    const qty = subscription.plan.qty;


    const onPress = () => {
        dispatch(startLoading());
        dispatch(processToken(dispatch));
    }

    return (
        <div className={cs.join(' ')}>
            <div className="subscription-submit-btn" onClick={onPress}>
                {`Suscríbete por ${currencyFormatter(qty)}`}
            </div>
        </div>
    )
}

export default SubscriptionSubmit;