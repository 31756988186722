import moment from 'moment';
import React from 'react'
import { useSelector } from 'react-redux';
import './styles.scss';

const DashboardUserInfo = () => {
    const { user } = useSelector(reduxState => reduxState.user);
    console.log('u', user);

    const m = moment(user.created);
    return (
        <div className="dashboard-user-info">
            <div className="dashboard-user-info-name">{user.name}</div>
            <div className="dashboard-user-info-email">{user.email}</div>
            <div className="dashboard-user-info-date">{`Desde el: ${m.locale('es').format('LL')}`}</div>
        </div>
    )
}

export default DashboardUserInfo;