import React from 'react'
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import Plan from '../Plan';
import { setPlan } from '../../shared/redux/subscription/actions';

const Plans = () => {
    const plans = useSelector((reduxState) => reduxState.info.data.plans);
    const selected = useSelector((reduxState) => reduxState.subscription.planID);
    const dispatch = useDispatch();

    const onPlanSelect = (planID) => {
        const plan = plans.filter((plan) => plan.ID === planID)[0];
        dispatch(setPlan(plan));
    }

    return (
        <div className="plans">
            {
                plans.map((plan) => <Plan key={plan.ID} data={plan} onSelect={() => onPlanSelect(plan.ID)} selected={selected === plan.ID}/>)
            }
        </div>
    )
}

export default Plans;