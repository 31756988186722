import React from 'react'
import './styles.scss';
import SubscriptionFormCard from '../SubscriptionFormCard';
import SubscriptionInput from '../SubscriptionInput';
import { useSelector, useDispatch } from 'react-redux';
import SubscriptionSubmit from '../SubscriptionSubmit';
import ReCAPTCHA from "react-google-recaptcha";
import { setRecaptcha } from '../../shared/redux/subscription/actions';

const SubscriptionForm = () => {
    const planId = useSelector((reduxState) => reduxState.subscription.planID);
    const dispatch = useDispatch();
    const onRecaptchaChange = (e) => {
        dispatch(setRecaptcha(e));
    }
    return planId ? (
        <div className="subscription-form">
            <SubscriptionFormCard />
            <SubscriptionInput placeholder="Correo electrónico" name="email"/>
            <SubscriptionInput placeholder="Nombre completo" name="name"/>
            <ReCAPTCHA
                sitekey="6LfQ_8QZAAAAADfMosrhwujJM-UdBVafemyoMNdc"
                onChange={onRecaptchaChange}
            />
            <SubscriptionSubmit />
        </div>
    ) : null
}

export default SubscriptionForm;