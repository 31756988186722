import { SET_PLAN, SET_USER_FIELD, SET_CARD_FIELD, SET_RECAPTCHA, PROCESS_TOKEN, SET_TOKEN, PROCESS_SUBSCRIPTION, WIPE_DATA } from './constants';
import { validteCard } from '../../helpers';

const initialState = {
  plan: {},
  planID: null,
  card: {},
  user: {},
  recaptcha: null,
  cardIsValid: false,
  cardToken: null
};

const subscription = (state = initialState, action) => {
  switch (action.type) {
    case WIPE_DATA: 
      return {
        ...state,
        planID: null,
        plan: {},
        card: {},
        recaptcha: null,
        user: {}
      }
    case SET_PLAN:
      return { 
        ...state,
        plan: action.plan,
        planID: action.plan.ID
      };
    case SET_USER_FIELD:
      const uf = state.user;
      uf[action.name] = action.value;
      uf.isValid = uf.email && uf.name;
      return {
        ...state,
        user: uf
      }
    case SET_CARD_FIELD:
      const {card} = state;
      card[action.field] = action.value;
      const cardIsValid = validteCard(card);
      return {
        ...state, 
        card,
        cardIsValid
      }
    case SET_RECAPTCHA:
      return {
        ...state,
        recaptcha: action.key
      }
    case SET_TOKEN:
      return {
        ...state,
        cardToken: action.token
      }
    case PROCESS_TOKEN:
      action['action'](state.card);
      return state;
    case PROCESS_SUBSCRIPTION:
      action['action'](state);
      return state;
    default:
      return state;
  }
};

export default subscription;
