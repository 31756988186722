import { GET_INFO, GET_INFO_SUCCESS } from './constants'

const initialState = {
    loading: true,
    data: {}
}

const info = (state = initialState, action) => {
    switch(action.type){
        case GET_INFO:
            return {...state, loading: true}
        case GET_INFO_SUCCESS:
            return {...state, loading: false, data: action.payload}
        default:
            return state;
    }
}

export default info;