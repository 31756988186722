import React from 'react'
import './styles.scss';
import config from '../../shared/config';
import assets from '../../shared/assets';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../shared/redux/store';
import { logoutUser } from '../../shared/redux/user/actions';

const Menu = () => {
    const dispatch = useDispatch();
    const user = useSelector(rs => rs.user);
    const isLogged = user.isBackendVerified;

    const cs = ['menu'];
    if(isLogged){
        cs.push('logged');
    }

    const logout = () => {
        dispatch(logoutUser());
        window.location.href = window.location.href;
    }

    return (
    <div className={cs.join(' ')} id="menu">
        <div className="menu-row">
            <div className="menu-row-td menu-logo">
                <a href={config.siteURL} target="_blank" rel="noopener noreferrer" title={config.siteOwnership}>
                    <img src={assets.logo} alt={config.siteOwnership}/>
                </a>
            </div>
            <div className="menu-row-td menu-nav">
                {
                    isLogged ?
                    <>
                    <div className="menu-nav-btn solid">
                        <Link to="/">
                            <div className="menu-nav-btn-btn">
                                {user.user.name}
                            </div>
                        </Link>
                    </div>
                    <div className="menu-nav-btn outlined">
                        <div className="menu-nav-btn-btn" onClick={logout}>
                            Cerrar sesión
                        </div>
                    </div>
                    </>
                    : 
                    <>
                        <div className="menu-nav-btn solid">
                            <Link to="/">
                                <div className="menu-nav-btn-btn">
                                    Suscríbete
                                </div>
                            </Link>
                        </div>
                        <div className="menu-nav-btn outlined">
                            <Link to="/login">
                                <div className="menu-nav-btn-btn">
                                    Inicia sesión
                                </div>
                            </Link>
                        </div>
                    </>
                }
                
            </div>
        </div>
    </div>
)}

export default Menu;