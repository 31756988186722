import cardValidator from 'card-validator';
import { useDispatch } from 'react-redux';
import { setToken } from './redux/subscription/actions';


export const currencyFormatter = (qty) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(qty);
}

export const validteCard = (card) => {
    const numberIsValid = cardValidator.number(card.number).isValid;
    const expirationIsValid = cardValidator.expirationDate(card.exp_month + card.exp_year).isValid;
    const cvcIsValid = cardValidator.cvv(card.cvc).isValid;

    return card.name && numberIsValid && cvcIsValid && expirationIsValid;
}
