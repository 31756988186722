import React from 'react'
import './styles.scss';
import Plans from '../Plans';
import SubscriptionForm from '../SubscriptionForm';
import assets from '../../shared/assets';

const SubscriptionBox = () => (
    <div className="subscription-box">
        <div className="subscription-box-h1">Elige un plan e ingresa tus datos</div>
        <div className="subscription-box-disclaimer">
            <p>
                Una vez que completes tu suscripción recibirás un correo electrónico con tus credenciales de ingreso, 
                con las que podrás ingresar a cualquiera de nuestros sitios libre de publicidad y con adelantos informativos.
            </p>
            <p>
                Tus datos están protegidos y no serán compartidos con terceros.
            </p>
        </div>
        <div className="subscription-box-plans">
            <Plans />
        </div>
        <div className="subscription-box-data">
            <SubscriptionForm />
        </div>
        <div className="subscription-box-medios">
              {
                assets.medios.map((src) => <div className="medio" style={{backgroundImage: `url(${src})`}} key={src}></div>)
              }
        </div>
    </div>
)

export default SubscriptionBox;