import React from 'react'
import './styles.scss';
import { useDispatch } from 'react-redux';
import { setUserField, setCardField } from '../../shared/redux/subscription/actions';

const SubscriptionInput = ({placeholder, name}) => {
    const dispatch = useDispatch();

    const onChange = (e) => {
        dispatch(setUserField(name, e.target.value));
        if(name === 'name'){
            dispatch(setCardField('name', e.target.value));
        }
    }

    return (
    <div className="input-container">
        <div className="subscription-input">
            <input type="text" placeholder={placeholder} onChange={onChange}/>
        </div>
    </div>
    )
}

export default SubscriptionInput;