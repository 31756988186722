import { SET_USER_LOGGED, SET_USER_ID, USER_LOGOUT } from './constants';
import * as Cookies from 'js-cookie';

const initialState = {
    isLogged: false,
    hash: Cookies.get('PDAP_session_hash'),
    isBackendVerified: false,
    userId: null,
    user: {}
}

const user = (state = initialState, action) => {
    switch(action.type){
        case SET_USER_LOGGED:
            return {
                ...state,
                isLogged: true,
                hash: action.hash
            }
        case SET_USER_ID:
            return {
                ...state,
                userId: action.user.id,
                user: action.user,
                isBackendVerified: true
            }
        case USER_LOGOUT:
            Cookies.set('PDAP_session_hash', null);
            return {
                isLogged: false,
                hash: null,
                isBackendVerified: false,
                userId: null,
                user: {}
            }
        default:
            return state;
    }
}

export default user;