import React from 'react'
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { hideAlert } from '../../shared/redux/alert/actions';

const Alert = () => {
    const alert = useSelector((reduxState) => reduxState.alert);
    const {show, title, content} = alert;
    const dispatch = useDispatch();

    const close = () => {
        dispatch(hideAlert());
    }  

    if(!show) return null
    return(
        <div className="alert">
            <div className="alert-bgclose" onClick={close}></div>
            <div className="alert-container">
                <div className="alert-container-title">{title}</div>
                <div className="alert-container-content">{content}</div>
                <div className="alert-container-button-container">
                    <div className="alert-container-button" onClick={close}>
                        Entendido
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Alert;