import React from 'react'
import './styles.scss';

const Button = ({onPress, disabled, title}) => {
    const _onPress = () => {
        onPress();
    }

    const cs = ['subscription-submit'];
    if(disabled){
        cs.push('invalid');
    }

    return (
        <div className={cs.join(' ')}>
            <div className="subscription-submit-btn" onClick={_onPress}>
                {title}
            </div>
        </div>
    )
}

export default Button;