import React from 'react'
import './styles.scss';
import { currencyFormatter } from '../../shared/helpers';

const Plan = ({onSelect, data, selected}) => {

    const cs = ["plan"];
    if(selected){
        cs.push('selected');
    }

    return (
        <div className={cs.join(' ')} onClick={onSelect}>
            <div className="plan-name">{data.name}</div>
            <div className="plan-qty">{currencyFormatter(data.qty)}</div>
            <div className="plan-term">Cada {data.term + ` mes${data.term > 1 ? 'es' : ''}`}</div>
            <div className="plan-description">{data.description}</div>
        </div>
    )
}

export default Plan;