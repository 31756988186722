import React, { useState } from "react";
import Wrapper from "../../components/Wrapper";

import './styles.scss';
import OverlayHero from "../../components/OverlayHero";
import assets from "../../shared/assets";
import Input from "../../components/Input";
import { Link, useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../shared/redux/loader/actions";
import { post } from "../../shared/services/http";
import { showAlert } from "../../shared/redux/alert/actions";

const RecoverPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState(null);

  const submit = async (e) => {
    dispatch(startLoading());
    const r = await post('/recoverpassword', {email});
    if(r.data.error){
      dispatch(showAlert('Error', r.data.response));
    }else{
      dispatch(showAlert('¡Éxito!', 'Te hemos enviado un correo con una contraseña temporal con la que podrás ingresar a tu cuenta.'));
      history.push('/login');
    }
    dispatch(stopLoading());
  }

  return(
    <Wrapper>
      <div className="binder">
        <OverlayHero images={assets.homeHero} />
        <div className="login-box">
          <div className="login-box-title">Recuperar contraseña</div>
          <div className="login-box-form">
            <form action="#" onSubmit={submit}>
              <Input 
                placeholder="Correo electrónico"
                value={email}
                onChangeText={(text) => setEmail(text)}
                type="email"
              />
              <div className="login-box-actions">
                <div className="login-box-actions-action">
                  <Link to="/login">
                    Iniciar sesión
                  </Link>
                </div>
              </div>
              <Button title="Recuperar" onPress={submit} disabled={!email}/>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default RecoverPassword;